.users-table picture {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.users-table tbody tr.active td {
    background-color: rgba(47, 73, 209, .1)
}

.users-table table,
.users-table-info {
    line-height: 1.4;
    letter-spacing: -.3px;
    color: #000;
}

.users-table table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    border: 1px solid #ddd;
}

.users-table th:first-child {
    padding-left: 20px;
}

.users-table td {
    background-color: #fff!important;
    border-top: 1px solid #ddd!important;
    border-bottom: 1px solid #ddd!important;
    padding-top: 15px!important;
    padding-bottom: 15px!important;
}

.users-table td:first-child {
    padding-left: 20px
}

.users-table tbody {
    font-size: 16px;
}

.users-table tbody tr:last-child .users-item-dropdown {
    bottom: 250%;
    top: auto
}

.users-table tbody tr:last-child .users-item-dropdown::before {
    bottom: -10px;
    top: auto;
    border-width: 15px 15px 0;
    border-color: #fff transparent transparent
}

.users-table .users-item-dropdown {
    right: -9px;
    top: 150%
}

.users-table img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 7px
}

.users-table-info {
    height: 48px;
    border-bottom: 1.2px solid #dadbe4;
    font-size: 14px;
    background: #fff;
}

.users-table-info th {
    font-weight: 600;
    color: #000;
    font-size: 18px;
}

.table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table-wrapper table.posts-table {
    min-width: 944px;
}

.pages-table-img {
    display: flex;
    align-items: center;
}