/* You can add global styles to this file, and also import other style files */

.ck-editor__editable_inline {
  min-height: 400px;
  min-width: 200px;
}

._multiSelect_drp_section {
  max-width: 270px !important;
}

._multiSelect_drp_section .multiselect-dropdown {
  background: #fff;
  padding: 0;
  margin-right: 10px;
  width: 250px;
  max-width: 250px;
}

._multiSelect_drp_section .multiselect-dropdown .dropdown-btn {
  border: 1px solid #d9d9d9;
  padding: 9px 12px 9px 12px;
  border-radius: 5px;
}

._multiSelect_drp_section .multiselect-dropdown .dropdown-btn .dropdown-down,
._multiSelect_drp_section .multiselect-dropdown .dropdown-btn .dropdown-up {
  border-top: 5px solid #000;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: relative;
  top: -3px;
  right: -6px;
}

._multiSelect_drp_section .multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 5px solid #000 !important;
  border-top: inherit;
}

._bg_transprent {
  background: transparent !important;
}

._multiSelect_drp_section._second_drp_section {
  max-width: 75% !important;
  display: inline-flex;
  padding-left: 0;
  padding-right: 0;
}

._multiSelect_drp_section._second_drp_section .form-input {
  padding-left: 0;
  width: 100%;
  padding-right: 0;
}

._multiSelect_drp_section._second_drp_section .multiselect-dropdown {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
}

#readMore {
  cursor: pointer;
}

._app_chart_box.col-sm-6 {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  flex: 0 0 49%;
  max-width: 49%;
  padding: 0;
}

._chart_title {
  background: #00ACD8;
  padding: 11px 15px;
  color: #fff;
  margin-bottom: 5px;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
}

._chart_body {
  padding: 15px;
}

._justify_content_space_even {
  justify-content: space-evenly;
}

.mr0 {
  margin-right: 0 !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 96% !important;
  line-height: 1.5 !important;
}

.tab-header {
  list-style-type: none;
  display: flex;
  padding: 0;
}

.tab-header li {
  cursor: pointer;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-bottom: none;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  margin-right: 5px;
  position: relative;
}

.tab-header li.active {
  background-color: #00acd8;
  color: #fff;
  border-color: #00acd8;
}

._form_account._white_bx {
  border-radius: 0 10px 10px 10px;
}

._btn_brd {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 20px;
}

.counts {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fc3434;
  color: #fff;
  border: 2px solid #fc3434;
  margin-right: 8px;
  font-weight: 700;
}

.tab-header li.active .counts {
  background-color: #27c24c;
  border-color: #fff;
}

.white-section {
  border-radius: 0 10px 10px 10px;
}

.blue-panel-body.white-section {
  border-radius: 0 10px 10px 10px;
}

.form-control {
  background-color: #eff0f6;
}

.ai_post_btn li {
  border: 1px solid #00ACD8;
  border-radius: 5px;
  padding: 0 15px;
  line-height: 1;
  height: 40px;
  background: #00ACD8;
  color: #fff;
}

.delete-post-img {
  font-size: 16px;
  background: red;
  width: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.delete-post-img:hover {
  color: #fff;
}

.no-img-post {
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 150px;
  border-radius: 8px;
  font-size: 14px;
  margin-top: 11px;
}

.form-check-input[type=checkbox] {
  border-color: #ccc !important;
}

.post-schedule-col {
  border: 1px solid #ddd !important;
  text-align: center;
  border-radius: 10px !important;
  padding: 30px !important;
}

body,
html {
  height:100%;
}